:root {
  font-family: Helvetica, sans-serif;
  --color-extra-light: #ecf6fe;
  --color-light: #b8ddfa;
  --color-background: #aed9f9;
  --color-light-shadow: #b6cbdd;
  --color-dark: #1d243f;
  --color-blueprint-pattern: #c3e1f9;
  --color-blueprint-pattern: #daebfb;
}

:focus {
  outline: 3px solid var(--color-dark);
  outline-offset: 0.25rem;
}
button:focus {
  outline: unset;
  outline-offset: unset;
}
input:focus {
  outline: 1px solid var(--color-dark);
  outline-offset: 0;
}

body {
  background: var(--color-background);
  /** stolen from https://leaverou.github.io/css3patterns/#blueprint-grid */
  background-image: linear-gradient(
      var(--color-blueprint-pattern) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, var(--color-blueprint-pattern) 2px, transparent 2px),
    linear-gradient(rgba(255, 255, 255, 0.3) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px);
  background-size: 125px 125px, 125px 125px, 25px 25px, 25px 25px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  color: var(--color-dark);
}

a {
  color: inherit;
}

.wrapper {
  max-width: 85ch;
  margin-left: auto;
  margin-right: auto;
}

header {
  padding: 3rem 4rem;
  padding-bottom: 2rem;
  outline: dotted 5px;
}

header a {
  text-decoration: none;
}
header a:hover {
  background: var(--color-extra-light);
  color: var(--color-dark);
}

nav ul {
  margin-top: 3rem;
  list-style: none;
  padding: 0;
  font-weight: bold;
  text-align: center;
}

nav ul > * + * {
  margin-top: 2rem;
}
nav a {
  padding: 0.5rem 0.75rem;
  background: var(--color-dark);
  color: var(--color-extra-light);
}

main {
  padding: 3rem 1rem;
  padding-bottom: 6rem;
}

main > * + * {
  margin-top: 5rem;
}

footer {
  background: var(--color-light);
  text-align: center;
  border-top: 5px dotted;
  padding: 1.5rem 1rem;
}

footer > * + * {
  margin-top: 0.75rem;
}

/** override the reset */
article > * + * {
  margin-top: 0;
}

article {
  background: var(--color-light);
  box-shadow: 0.75rem 0.75rem 0 var(--color-dark);
  border: 3px solid;
}

.article--top > * + * {
  margin-top: 2rem;
}

.article--top {
  padding: 2.25rem;
}

.site-title {
  text-transform: uppercase;
  line-height: 1.1;
  font-family: "Inter";
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  padding: 0.75rem;
  box-shadow: 0.5rem 0.5rem 0 var(--color-dark);
}
.site-title--outer {
  background: var(--color-extra-light);
  box-shadow: inset 0.25rem 0.25rem 0 var(--color-light-shadow);
  border: 3px solid;
}

h2 a {
  font-weight: normal;
}

h2 {
  line-height: 1.2;
  scroll-margin: 4rem;
  font-family: "Inter";
  font-size: 2rem;
}

.with-sidebar {
  overflow: hidden;
  --min-width: 50%;
}

.with-sidebar > * {
  display: flex;
  flex-wrap: wrap;
}

.with-sidebar > * > * {
  flex-grow: 1;
}

.with-sidebar > * > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: var(--min-width);
}

form {
  border-top: 3px solid;
  padding: 2.25rem;
}
#paypal {
  display: inline;
  border: none;
  padding: 0;
}
#paypal button {
  font-weight: bold;
}

form > * + * {
  margin-top: 2rem;
}
#paypal > * + * {
  margin-top: 0;
}

.inputs {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  justify-content: start;
  align-items: center;
}

label {
  text-align: right;
}

input[type="text"] {
  background: var(--color-extra-light);
  border: 2px solid;
  padding: 0.25rem 0.5rem;
  box-shadow: inset 0.15rem 0.15rem 0 var(--color-light-shadow);
  width: 8ch;
}

input[inputmode="numeric"],
input[inputmode="decimal"] {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}

button {
  font-family: "Inter";
  border: none;
  background: var(--color-dark);
  color: var(--color-extra-light);
  font-weight: bold;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
}

.triangle {
  display: grid;
  place-items: center;
  background: var(--color-dark);
  color: var(--color-extra-light);
  padding: 2rem;
}

.triangle > * + * {
  margin-top: 1rem;
}

svg {
  font-size: 0.65rem;
  font-weight: bold;
}
svg text {
  fill: currentColor;
}

.temporary {
  stroke-dasharray: 3 1.5;
}

.animated-shape {
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
}
.animate .animated-shape {
  animation: dash 1.25s linear forwards;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.delayed-text {
  opacity: 0;
}
.animate .delayed-text {
  animation: delay steps(1) forwards;
  animation-delay: 0.95s;
}
@keyframes delay {
  to {
    opacity: 1;
  }
}

#slide-CB {
  display: none;
  width: 100%;
}
#slide-CB.show {
  display: block;
}

.stairs-svg {
  font-size: 0.5rem;
}

#stairs-result {
  font-weight: bold;
  width: 100%;
  display: none;
}

#stairs-result > * + * {
  margin-top: 0.75rem;
}
#stairs-result .input-group > * + * {
  margin-top: 0.25rem;
}

#stairs-result.show {
  display: block;
}
#slide-stairs-L,
#slide-stairs-count {
  width: 100%;
}

#ramp_steps > * + * {
  margin-top: 1.25rem;
}

ol.ramp-sections {
  list-style: revert;
}
.ramp-sections > * + * {
  margin-top: 1rem;
}
.btn-link {
  background: none;
  color: inherit;
  text-decoration: underline;
  font-weight: normal;
  padding: 0;
  font-family: Helvetica, sans-serif;
}

.ramp-sections button {
  margin-left: 1rem;
  font-size: 0.85rem;
}
